import * as axos from "axios"

const axios = axos.create()
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 403) {
      localStorage.clear()
      if (typeof window !== "undefined") {
        window?.location?.reload()
      }
    }
    return Promise.reject(error)
  }
)

export default axios
