import React, { createContext, useReducer } from "react"

import setAuthToken from "../utils/set-auth-token"
import { config } from "../constants/data/config"

const initialState = {
  isAuthenticated: false,
  isEmailVerified: false,
  hasProfile: false,
  user: null,
  token: null,
  config: null,
  global_options: config.options.acf,
  primary_menu: config.menu_1_header.items,
  secondary_menu: config.menu_2_login_signup.items,
  footer_menu_one: config.menu_3_footer_column_1.items,
  footer_menu_two: config.menu_4_footer_column_2.items,
  modal: {
    showModal: 0,
  },
}

export const GlobalAuthContext = createContext(initialState)

function reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token)
      localStorage.setItem("user", JSON.stringify(action.payload.user))
      setAuthToken(action.payload.token)
      return {
        ...state,
        isAuthenticated: true,
        isEmailVerified: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    case "LOGIN_NOT_VERIFIED":
      localStorage.setItem("token", action.payload)
      localStorage.setItem("user", JSON.stringify({ isEmailVerified: false }))
      const user = { candidate: { verified: false } }
      setAuthToken(action.payload)
      return {
        ...state,
        isAuthenticated: true,
        isEmailVerified: false,
        user: user,
        token: action.payload,
      }
    case "LOGIN_WITH_REMEMBER_ME":
      localStorage.setItem("token", action.payload.token)
      localStorage.setItem("user", JSON.stringify(action.payload.user))
      setAuthToken(action.payload.token)
      return {
        ...state,
        isAuthenticated: true,
        isEmailVerified: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    case "LOGOUT":
      localStorage.clear()
      setAuthToken(null)
      return {
        isAuthenticated: false,
        isEmailVerified: false,
        hasProfile: false,
        user: null,
        token: null,
        config: null,
        global_options: config.options.acf,
        primary_menu: config.menu_1_header.items,
        secondary_menu: config.menu_2_login_signup.items,
        footer_menu_one: config.menu_3_footer_column_1.items,
        footer_menu_two: config.menu_4_footer_column_2.items,
        modal: {
          showModal: 0,
        },
      }
    case "PROFILE_VERIFIED":
      return {
        ...state,
        hasProfile: action.payload.verified,
        profile: action.payload.data,
      }
    case "JOB_APPLICANTS":
      return {
        ...state,
        job_detail_with_applicants: action.payload,
      }
    case "COMPANY_DATA":
      return {
        ...state,
        company_data: action.payload,
      }
    case "COMPANY_PROFILE_UPDATE":
      return {
        ...state,
        profile: { ...action.payload, ...action?.payload?.profile },
      }
    case "COMPANY_JOB_POSTED":
      return {
        ...state,
        job_posted_list: action.payload,
      }
    case "INDUSTRY_SKILLS":
      return {
        ...state,
        industry_skills: action.payload,
      }
    case "HAS_PROFILE":
      return {
        ...state,
        hasProfile: action.payload,
      }
    // case "GLOBAL":
    //   return {
    //     ...state,
    //     global_options: action.payload,
    //   }
    case "MODAL":
      return {
        ...state,
        modal: action.payload,
      }
    case "BLOG_POSTS":
      return {
        ...state,
        blog_posts: action.payload,
      }
    case "BLOG_POSTS_PICKS":
      return {
        ...state,
        blog_posts_picks: action.payload,
      }
    // case "GLOBAL_MENU":

    //   return {
    //     ...state,
    //     global_options: action.payload.global,
    //     primary_menu: action.menu_1,
    //     secondary_menu: action.menu_2,
    //     footer_menu_one: action.menu_3,
    //     footer_menu_two: action.menu_3,
    //   }

    // case "PRIMARY_MENU":
    //   return {
    //     ...state,
    //     primary_menu: action.payload,
    //   }
    // case "SECONDARY_MENU":
    //   return {
    //     ...state,
    //     secondary_menu: action.payload,
    //   }
    // case "FOOTER_MENU_ONE":
    //   return {
    //     ...state,
    //     footer_menu_one: action.payload,
    //   }
    // case "FOOTER_MENU_TWO":
    //   return {
    //     ...state,
    //     footer_menu_two: action.payload,
    //   }
    case "META_DATA_LISTING":
      return {
        ...state,
        meta_data: action.payload,
      }
    case "BLOG_PAGINATION":
      return {
        ...state,
        blog_pagination: action.payload,
      }
    case "RELATED_POSTS":
      return {
        ...state,
        related: action.payload,
      }
    case "CART_DATA":
      return {
        ...state,
        cart: action.payload,
      }
    case "LOGIN_ERROR":
      return {
        ...state,
        login_error: action.payload,
      }
    case "SHORTLIST_DATA":
      return {
        ...state,
        shortlist: action.payload,
      }
    case "SET_CONFIG":
      return {
        ...state,
        config: action.payload,
      }
    case "COMPANY_COUNTS":
      return {
        ...state,
        company_counts: action.payload,
      }
    case "CANDIDATE_COUNTS":
      return {
        ...state,
        candidate_counts: action.payload,
      }
    case "LAYOUT_REFRESH":
      return {
        ...state,
        layout_refresh: action.payload,
      }
    default:
      return state
  }
}

const GlobalAuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  React.useEffect(() => {
    if (localStorage.token === "undefined" || localStorage.token === "") {
      dispatch({
        type: "LOGOUT",
      })
    }
    if (localStorage.user && localStorage.token) {
      const user = JSON.parse(localStorage.getItem("user"))
      if (localStorage.token && user.role === 2) {
        const token = localStorage.getItem("token")
        dispatch({
          type: "LOGIN",
          payload: {
            token: token,
            user: user,
          },
        })
      } else if (localStorage.token && user.role === 3) {
        const token = localStorage.getItem("token")
        dispatch({
          type: "LOGIN",
          payload: {
            token: token,
            user: user,
          },
        })
      } else if (localStorage.token && !user.role) {
        const token = localStorage.getItem("token")
        dispatch({
          type: "LOGIN_NOT_VERIFIED",
          payload: token,
        })
      } else if (localStorage.token === "undefined") {
        dispatch({
          type: "LOGOUT",
        })
      } else {
        dispatch({
          type: "LOGOUT",
        })
      }
    } else {
      dispatch({
        type: "LOGOUT",
      })
    }
  }, [])
  return (
    <GlobalAuthContext.Provider value={{ state, dispatch }}>
      {/* {console.log("initial", initialState)} */}
      {children}
    </GlobalAuthContext.Provider>
  )
}

export default GlobalAuthContextProvider
