/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "./node_modules/bootstrap/dist/css/bootstrap.css"
import GlobalAuthContextProvider from "./src/context/authContext"
import MainLayout from "./src/components/layout/main-layout"

export const wrapRootElement = ({ element }) => {
  return <GlobalAuthContextProvider>{element}</GlobalAuthContextProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return <MainLayout {...props}>{element}</MainLayout>
}
