import Logo from "../../images/logo.svg"

export const config = {
  options: {
    acf: {
      contact_information:
        '<h3>Contact Information</h3>\n<ul>\n<li><strong>WDN &#8211; Workers&#8217; Directory Nexus</strong></li>\n<li>Baneshwor, Kathmandu, Nepal</li>\n<li><a href="mailto:hi@wdnexus.com ">Email: hi@wdnexus.com </a></li>\n</ul>\n',
      copyright:
        "<p>\u00a9 2023 <strong>WDN</strong>, All Rights Reserved.</p>\n",
      description:
        '<div class="mt-3 mb-4"><span class="footer-section__motto">WDN &#8211; A simple and effective way to connect Companies with Candidates.</span></div>\n',
      fav_icon: false,
      logo: {
        ID: 901,
        id: 901,
        title: "logo",
        filename: "logo-1.svg",
        filesize: 14111,
        url: Logo,
        link: "https://dev.wdnexuswp.com/logo/",
        alt: "WDN - Workers' Directory Nexus",
        author: "1",
        description: "",
        caption: "",
        name: "logo",
        status: "inherit",
        uploaded_to: 0,
        date: "2021-11-02 10:38:21",
        modified: "2021-11-02 10:38:37",
        menu_order: 0,
        mime_type: "image/svg+xml",
        type: "image",
        subtype: "svg+xml",
        icon: "https://dev.wdnexuswp.com/wp-includes/images/media/default.png",
        width: false,
        height: false,
        sizes: {
          thumbnail:
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "thumbnail-width": false,
          "thumbnail-height": false,
          medium:
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "medium-width": false,
          "medium-height": false,
          medium_large:
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "medium_large-width": false,
          "medium_large-height": false,
          large:
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "large-width": false,
          "large-height": false,
          "1536x1536":
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "1536x1536-width": false,
          "1536x1536-height": false,
          "2048x2048":
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "2048x2048-width": false,
          "2048x2048-height": false,
          "post-thumbnail":
            "https://dev.wdnexuswp.com/wp-content/uploads/2021/11/logo-1.svg",
          "post-thumbnail-width": false,
          "post-thumbnail-height": false,
        },
      },
      social_networks: [
        {
          network_icon: '<i class="fab fa-facebook-f" aria-hidden="true"></i>',
          network_url: "https://www.facebook.com/workersdirectorynexus",
        },
        {
          network_icon: '<i class="fab fa-instagram" aria-hidden="true"></i>',
          network_url: "https://www.instagram.com/wdnexus/",
        },
        {
          network_icon: '<i class="fab fa-twitter" aria-hidden="true"></i>',
          network_url: "https://twitter.com/WorkersNexus",
        },
      ],
    },
  },
  menu_1_header: {
    term_id: 3,
    name: "Menu 1 - Header",
    slug: "menu-1-header",
    term_group: 0,
    term_taxonomy_id: 3,
    taxonomy: "nav_menu",
    description: "",
    parent: 0,
    count: 5,
    filter: "raw",
    items: [
      {
        ID: 1876,
        post_author: "1",
        post_date: "2022-07-18 09:27:37",
        post_date_gmt: "2022-07-18 09:27:37",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "1876",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:37",
        post_modified_gmt: "2022-07-18 09:27:37",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=1876",
        menu_order: 1,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 1876,
        menu_item_parent: "0",
        object_id: "218",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/for-companies/",
        title: "For Companies",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "for-companies",
      },
      {
        ID: 1875,
        post_author: "1",
        post_date: "2022-07-18 09:27:37",
        post_date_gmt: "2022-07-18 09:27:37",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "1875",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:37",
        post_modified_gmt: "2022-07-18 09:27:37",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=1875",
        menu_order: 2,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 1875,
        menu_item_parent: "0",
        object_id: "220",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/for-candidates/",
        title: "For Candidates",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "for-candidates",
      },
      {
        ID: 598,
        post_author: "1",
        post_date: "2022-07-18 09:27:37",
        post_date_gmt: "2021-04-27 17:59:24",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "598",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:37",
        post_modified_gmt: "2022-07-18 09:27:37",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=598",
        menu_order: 3,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 598,
        menu_item_parent: "0",
        object_id: "226",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/about/",
        title: "About",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "about",
      },
      {
        ID: 1101,
        post_author: "1",
        post_date: "2022-07-18 09:27:37",
        post_date_gmt: "2021-12-09 03:20:38",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "1101",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:37",
        post_modified_gmt: "2022-07-18 09:27:37",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=1101",
        menu_order: 4,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 1101,
        menu_item_parent: "0",
        object_id: "1098",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/faqs/",
        title: "FAQs",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "faqs",
      },
      //   {
      //     ID: 599,
      //     post_author: "1",
      //     post_date: "2022-07-18 09:27:37",
      //     post_date_gmt: "2021-04-27 17:59:24",
      //     post_content: " ",
      //     post_title: "",
      //     post_excerpt: "",
      //     post_status: "publish",
      //     comment_status: "closed",
      //     ping_status: "closed",
      //     post_password: "",
      //     post_name: "599",
      //     to_ping: "",
      //     pinged: "",
      //     post_modified: "2022-07-18 09:27:37",
      //     post_modified_gmt: "2022-07-18 09:27:37",
      //     post_content_filtered: "",
      //     post_parent: 0,
      //     guid: "https://dev.wdnexuswp.com/?p=599",
      //     menu_order: 5,
      //     post_type: "nav_menu_item",
      //     post_mime_type: "",
      //     comment_count: "0",
      //     filter: "raw",
      //     db_id: 599,
      //     menu_item_parent: "0",
      //     object_id: "228",
      //     object: "page",
      //     type: "post_type",
      //     type_label: "Page",
      //     url: "https://dev.wdnexuswp.com/blog/",
      //     title: "Blog",
      //     target: "",
      //     attr_title: "",
      //     description: "",
      //     classes: [""],
      //     xfn: "",
      //     slug: "blog",
      //   },
    ],
  },
  menu_2_login_signup: {
    term_id: 4,
    name: "Menu 2 - Login/Signup",
    slug: "menu-2-login-signup",
    term_group: 0,
    term_taxonomy_id: 4,
    taxonomy: "nav_menu",
    description: "",
    parent: 0,
    count: 2,
    filter: "raw",
    items: [
      {
        ID: 245,
        post_author: "1",
        post_date: "2021-01-28 08:56:50",
        post_date_gmt: "2021-01-28 08:56:50",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "245",
        to_ping: "",
        pinged: "",
        post_modified: "2021-01-28 08:56:50",
        post_modified_gmt: "2021-01-28 08:56:50",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=245",
        menu_order: 1,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 245,
        menu_item_parent: "0",
        object_id: "222",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/login/",
        title: "Login",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "login",
      },
      {
        ID: 244,
        post_author: "1",
        post_date: "2021-01-28 08:56:50",
        post_date_gmt: "2021-01-28 08:56:50",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "244",
        to_ping: "",
        pinged: "",
        post_modified: "2021-01-28 08:56:50",
        post_modified_gmt: "2021-01-28 08:56:50",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=244",
        menu_order: 2,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 244,
        menu_item_parent: "0",
        object_id: "224",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/signup/",
        title: "Signup",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "signup",
      },
    ],
  },
  menu_3_footer_column_1: {
    term_id: 5,
    name: "Menu 3 - Footer Column 1",
    slug: "menu-3-footer-column-1",
    term_group: 0,
    term_taxonomy_id: 5,
    taxonomy: "nav_menu",
    description: "",
    parent: 0,
    count: 4,
    filter: "raw",
    items: [
      //   {
      //     ID: 1291,
      //     post_author: "1",
      //     post_date: "2022-01-04 10:26:44",
      //     post_date_gmt: "2022-01-04 10:26:44",
      //     post_content: " ",
      //     post_title: "",
      //     post_excerpt: "",
      //     post_status: "publish",
      //     comment_status: "closed",
      //     ping_status: "closed",
      //     post_password: "",
      //     post_name: "1291",
      //     to_ping: "",
      //     pinged: "",
      //     post_modified: "2022-01-04 10:26:44",
      //     post_modified_gmt: "2022-01-04 10:26:44",
      //     post_content_filtered: "",
      //     post_parent: 0,
      //     guid: "https://dev.wdnexuswp.com/?p=1291",
      //     menu_order: 1,
      //     post_type: "nav_menu_item",
      //     post_mime_type: "",
      //     comment_count: "0",
      //     filter: "raw",
      //     db_id: 1291,
      //     menu_item_parent: "0",
      //     object_id: "228",
      //     object: "page",
      //     type: "post_type",
      //     type_label: "Page",
      //     url: "https://dev.wdnexuswp.com/blog/",
      //     title: "Blog",
      //     target: "",
      //     attr_title: "",
      //     description: "",
      //     classes: [""],
      //     xfn: "",
      //     slug: "blog",
      //   },
      {
        ID: 243,
        post_author: "1",
        post_date: "2022-01-04 10:26:44",
        post_date_gmt: "2021-01-28 08:56:13",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "243",
        to_ping: "",
        pinged: "",
        post_modified: "2022-01-04 10:26:44",
        post_modified_gmt: "2022-01-04 10:26:44",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=243",
        menu_order: 2,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 243,
        menu_item_parent: "0",
        object_id: "226",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/about/",
        title: "About",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "about",
      },
      {
        ID: 240,
        post_author: "1",
        post_date: "2022-01-04 10:26:44",
        post_date_gmt: "2021-01-28 08:56:13",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "240",
        to_ping: "",
        pinged: "",
        post_modified: "2022-01-04 10:26:44",
        post_modified_gmt: "2022-01-04 10:26:44",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=240",
        menu_order: 3,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 240,
        menu_item_parent: "0",
        object_id: "232",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/privacy/",
        title: "Privacy Policy",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "privacy",
      },
      {
        ID: 239,
        post_author: "1",
        post_date: "2022-01-04 10:26:44",
        post_date_gmt: "2021-01-28 08:56:13",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "239",
        to_ping: "",
        pinged: "",
        post_modified: "2022-01-04 10:26:44",
        post_modified_gmt: "2022-01-04 10:26:44",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=239",
        menu_order: 4,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 239,
        menu_item_parent: "0",
        object_id: "234",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/tac/",
        title: "Terms &#038; Conditions",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "tac",
      },
    ],
  },
  menu_4_footer_column_2: {
    term_id: 6,
    name: "Menu 4 - Footer Column 2",
    slug: "menu-4-footer-column-2",
    term_group: 0,
    term_taxonomy_id: 6,
    taxonomy: "nav_menu",
    description: "",
    parent: 0,
    count: 3,
    filter: "raw",
    items: [
      {
        ID: 1292,
        post_author: "1",
        post_date: "2022-07-18 09:27:52",
        post_date_gmt: "2022-01-04 10:29:51",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "1292",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:52",
        post_modified_gmt: "2022-07-18 09:27:52",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=1292",
        menu_order: 1,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 1292,
        menu_item_parent: "0",
        object_id: "1098",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/faqs/",
        title: "FAQs",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "faqs",
      },
      {
        ID: 236,
        post_author: "1",
        post_date: "2022-07-18 09:27:52",
        post_date_gmt: "2021-01-28 08:55:31",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "236",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:52",
        post_modified_gmt: "2022-07-18 09:27:52",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=236",
        menu_order: 2,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 236,
        menu_item_parent: "0",
        object_id: "220",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/for-candidates/",
        title: "For Candidates",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "for-candidates",
      },
      {
        ID: 237,
        post_author: "1",
        post_date: "2022-07-18 09:27:52",
        post_date_gmt: "2021-01-28 08:55:31",
        post_content: " ",
        post_title: "",
        post_excerpt: "",
        post_status: "publish",
        comment_status: "closed",
        ping_status: "closed",
        post_password: "",
        post_name: "237",
        to_ping: "",
        pinged: "",
        post_modified: "2022-07-18 09:27:52",
        post_modified_gmt: "2022-07-18 09:27:52",
        post_content_filtered: "",
        post_parent: 0,
        guid: "https://dev.wdnexuswp.com/?p=237",
        menu_order: 3,
        post_type: "nav_menu_item",
        post_mime_type: "",
        comment_count: "0",
        filter: "raw",
        db_id: 237,
        menu_item_parent: "0",
        object_id: "218",
        object: "page",
        type: "post_type",
        type_label: "Page",
        url: "https://dev.wdnexuswp.com/for-companies/",
        title: "For Companies",
        target: "",
        attr_title: "",
        description: "",
        classes: [""],
        xfn: "",
        slug: "for-companies",
      },
    ],
  },
}
