// import axios from "axios"
import axios from "../constants/axios"

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token
  } else {
    delete axios.defaults.headers.common["Authorization"]
  }
}

export default setAuthToken
